.properties {
  display: flex;
  gap: 10px;
  align-items: center;
  .btn-add {
    margin-top: 30px;
  }
  .props-row {
    display: flex;
    flex-direction: column;
  }
}
