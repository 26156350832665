.nft_attr {
  display: block;
  padding: 10px;
  text-align: center;
  background: rgba(252, 52, 63, 0.1);
  border: solid 1px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  margin-bottom: 8px;
  text-decoration: none;
  position: relative;
  .remove-icon {
    width: 20px;
    height: 20px;
    position: absolute;
    right: -8px;
    top: -8px;
    color: var(--color_more);
    font-size: 15px;
    border-radius: 50%;
    display: none;
    cursor: pointer;
  }
  &:hover {
    .remove-icon {
      display: block !important;
    }
  }
  h5 {
    color: var(--white);
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
  }
  h4 {
    font-size: 15px;
    font-weight: 700;
    color: var(--white);
    margin-bottom: 0;
  }
  span {
    font-size: 13px;
    color: var(--white);
  }
}
