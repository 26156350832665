.container {
  .egg-image {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .xp-status {
    width: 60%;
    display: flex;
    flex-direction: column;
    .xp-bar {
      width: 100%;
      height: 15px;
      border-radius: 20px;
      border: 1px solid;
      margin: auto;
      .xp {
        height: 100%;
        border-radius: 20px;
        background: greenyellow;
      }
    }
    .level {
      display: flex;
      justify-content: space-between;
    }
    .color-white {
      color: white;
      font-weight: 700;
    }
    .small-text {
      font-size: 0.8em;
    }
  }
}
